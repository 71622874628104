////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(69, 90, 100);
}
.bg-secondaryColor {
  background-color: rgb(154, 205, 50);
}
.bg-thirdColor {
  background-color: rgb(255, 255, 255);
}
.bg-fourthColor {
  background-color: rgb(255, 255, 255);
}
.bg-fifthColor {
  background-color: rgb(0, 0, 0);
}
.bg-sixthColor {
  background-color: rgb(255, 255, 255);
}
.bg-seventhColor {
  background-color: rgb(0, 0, 0);
}
